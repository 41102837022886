//client section//

.client-swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .swiper-wrapper {
        text-align: center;
        .swiper-slide {
            text-align: center;
            background: var(--white);

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            .swiper-slide img {
                display: block;
                object-fit: cover;
            }
        }
    }
    img {
        height: 40px;
        max-width: 130px;
        filter: grayscale(1);
        opacity: 0.6;
        @include transitionSlow;
        cursor: pointer;
        &:hover {
            filter: grayscale(0);
            opacity: 1;
        }
    }
}

.books-card {
    .dz-media {
        img {
            width: 100%;
            border-radius: var(--border-radius-base);
        }
    }

    // Style 1
    &.style-1 {
        text-align: center;
        .dz-content {
            margin-top: 10px;
            .price {
                color: var(--primary);
                display: block;
                font-family: poppins;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 12px;
            }
            @include respond('desktop') {
                a {
                    padding: 18px 22px;
                }
            }
        }
    }

    // Style 2
    &.style-2 {
        display: flex;
        .dz-media {
            width: 335px;
            min-width: 335px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: var(--border-radius-base);
            }
        }
        .dz-content {
            padding-left: 50px;
            .sub-title {
                letter-spacing: 20px;
                font-weight: var(--headings-font-weight3);
            }
            .title {
                font-size: 60px;
            }
            .dz-tags {
                display: flex;
                li {
                    margin-right: 25px;
                    font-size: 16px;
                    font-weight: var(--headings-font-weight2);
                    font-family: var(--font-family-title);
                    color: var(--secondary);
                }
            }
            .text {
                padding-left: 30px;
                margin: 40px 0px 40px 0px;
                border-left: 1px solid #3b2f4a;
            }

            .price {
                align-items: center;
                display: inline-flex;
                .price-num {
                    font-size: 50px;
                    color: var(--secondary);
                    font-weight: var(--headings-font-weight);
                    font-family: var(--font-family-title);
                }
                del {
                    color: var(--primary);
                    font-size: 22px;
                    padding: 0 20px;
                }
                .badge {
                    font-size: 14px;
                    font-weight: var(--headings-font-weight);
                    color: #131045;
                    background-color: #f0f0f0;
                    padding: 8px 12px;
                }
            }
        }
        @include respond('tab-land') {
            .dz-content {
                .title {
                    font-size: 48px;
                }
            }
        }
        @include respond('tab-port') {
            .dz-content {
                padding-left: 30px;
            }
        }
        @include respond('phone-land') {
            .dz-media {
                width: 200px;
                min-width: 200px;
            }
            .dz-content {
                padding-left: 20px;
                .title {
                    font-size: 32px;
                }
                .text {
                    padding-left: 20px;
                    margin-top: 20px;
                }
                .price {
                    .price-num {
                        font-size: 32px;
                    }
                }
            }
        }
        @include respond('phone') {
            display: block;
            .dz-media {
                width: 100%;
                min-width: 100%;
            }
            .dz-content {
                padding-left: 0;
                margin-top: 25px;
                .dz-tags {
                    li {
                        margin-right: 13px;
                    }
                }
            }
        }
    }

    // Style 3
    &.style-3 {
        .dz-content {
            padding-top: 20px;
            .dz-tags {
                display: inline-flex;
                margin-bottom: 5px;
                li {
                    color: var(--primary);
                    margin-right: 10px;
                    font-size: 14px;
                }
            }
            .book-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .rate {
                    color: var(--primary);
                    font-size: 18px;
                    display: flex;
                    i {
                        margin-right: 5px;
                    }
                }
                .price {
                    .price-num {
                        font-weight: var(--headings-font-weight);
                        font-size: 18px;
                        color: var(--secondary);
                    }
                    del {
                        font-weight: var(--headings-font-weight);
                        font-size: 15px;
                        color: #aaaaaa;
                        font-family: var(--font-family-title);
                        padding: 0 5px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    // Style 4
    &.style-4 {
        border-radius: var(--border-radius-base);
        display: flex;
        align-items: center;
        padding: 40px 40px;
        margin-bottom: 10px;
        overflow: hidden;
        z-index: 1;
        position: relative;
        height: calc(100% - 15px);

        &:after {
            content: '';
            position: absolute;
            background-color: rgba(19, 16, 69, 0.9);
            z-index: -1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .dz-media {
            img {
                width: 100%;
            }
            width: 120px;
            min-width: 120px;
        }
        .dz-content {
            padding-left: 30px;
            padding-right: 15px;
            width: 100%;
            .title {
                margin-bottom: 5px;
                a {
                    color: $white;
                }
            }
            .dz-meta {
                color: #fff;
                font-weight: var(--headings-font-weight);
                margin-bottom: 15px;
                li {
                    font-weight: 600;
                    font-size: 14px;
                    a {
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }
            p {
                color: $white;
                margin-bottom: 0;
            }
        }
        .book-footer {
            text-align: right;
            min-width: 150px;
            .price {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;

                .price-num {
                    color: $white;
                    font-size: 30px;
                    font-weight: var(--headings-font-weight);
                    font-family: var(--font-family-title);
                }
                del {
                    color: #ee7979;
                    font-family: var(--font-family-title);
                    font-size: 22px;
                    font-weight: var(--headings-font-weight2);
                    padding-left: 10px;
                }
            }
        }
        .bg-media {
            position: absolute;
            width: 80%;
            z-index: -1;
            top: -45%;
            right: -53%;
            object-position: center center;
            filter: blur(10px);
            transform: rotate(-17deg);
        }

        &.card-lg {
            .dz-media {
                width: 255px;
                min-width: 255px;
            }
            .dz-content {
                padding-left: 0;
                padding-right: 50px;
            }
            .book-footer {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 50px;

                .price {
                    margin-bottom: 0px;
                    margin-right: 30px;
                }
            }
        }
        &.card-light {
            border: 1px solid #f1f1f1;
            p {
                color: #777777;
            }
            .title a,
            .price .price-num,
            .dz-meta li,
            .dz-meta li a {
                color: var(--secondary);
            }
            &:after {
                background-color: rgba(255, 255, 255, 0.9);
            }
        }
        @include respond('laptop') {
            &.card-lg {
                .dz-media {
                    width: 200px;
                    min-width: 200px;
                }
                .dz-content {
                    padding-right: 30px;
                }
            }
        }
        @include respond('phone-land') {
            padding: 20px;
            .dz-content {
                .title {
                    margin-bottom: 0;
                }
                .dz-meta {
                    margin-bottom: 5px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        @include respond('phone') {
            display: block;
            .dz-content {
                margin-top: 15px;
                padding-left: 0px;
                padding-right: 0px;
            }
            .book-footer {
                display: flex;
                .price {
                    margin-bottom: 0px;
                    padding-right: 10px;
                }
            }
            &.card-lg {
                .dz-content {
                    padding-right: 0px;
                }
                .dz-media {
                    margin-top: 20px;
                    min-width: 320px;
                    width: 320px;
                }
                .book-footer {
                    .price {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

.swiper-container {
    &.books-wrapper-2 {
        overflow: unset;
    }
}
.books-wrapper-2 {
    margin: auto;
    max-width: 960px;
    .swiper-slide {
        .books-card {
            opacity: 0.5;
        }
        &.swiper-slide-active {
            .books-card {
                opacity: 1;
            }
        }
    }
}
.books-wrapper-4 {
    .swiper-slide {
        .books-card {
            opacity: 1;
        }
    }
}

.swiper-pagination-two,
.swiper-pagination-three,
.swiper-pagination-four,
.swiper-pagination-five {
    .swiper-pagination-bullet {
        background-color: #dbdbdb;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin: 5px;
        &.swiper-pagination-bullet-active {
            background-color: var(--primary);
        }
    }
}
.swiper-pagination-two {
    @include respond('phone') {
        display: none;
    }
}
.swiper-pagination-four {
    text-align: center;
    margin-top: 50px;
    .swiper-pagination-bullet {
        width: 22px;
        height: 8px;
        border-radius: 7px;
        &.swiper-pagination-bullet-active {
            width: 45px;
            height: 8px;
        }
    }
}

.pagination-align {
    display: flex;
    align-items: center;
    //style-1
    &.style-1 {
        .swiper-button-prev {
            margin-right: 10px;
        }
        .swiper-button-next {
            margin-left: 10px;
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: unset;
            top: 0;
            align-items: center;
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: $white;
            background-color: var(--primary);
            border-radius: 50%;
            margin-top: 0;
            &:after {
                content: '';
            }
        }
        .swiper-pagination-two {
            &.swiper-pagination-bullets {
                margin-top: 8px;
            }
        }
    }
    //style-2
    &.style-2 {
        justify-content: center;
        margin-top: 70px;
        .swiper-button-prev {
            margin-right: 10px;
        }
        .swiper-button-next {
            margin-left: 10px;
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: unset;
            top: 0;
            margin-top: -6px;
            align-items: center;
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: $white;
            background-color: var(--primary);
            border-radius: 50%;
            &:after {
                content: '';
            }
        }
        @include respond('phone') {
            margin-top: 40px;
        }
    }
    //style-3
    &.style-3 {
        .swiper-button-prev {
            margin-right: 45px;
        }
        .swiper-button-next {
            margin-left: 10px;
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: unset;
            top: 0;
            width: 25px;
            height: 25px;
            font-size: 24px;
            color: #090606;
            align-items: center;
            @include transitionFast;
            &:hover {
                color: var(--primary);
            }
            &:after {
                content: '';
            }
        }
        @media screen and (max-width: 360px) {
            display: none;
        }
    }
}
.reccomend {
    position: relative;
    overflow: hidden;
    .section-head {
        img {
            position: absolute;
            left: 655px;
            top: 162px;
        }
        @include respond('wide-desktop') {
            img {
                left: 502px;
                top: 121px;
            }
        }
    }
}

.testimonial {
    .section-head {
        p {
            max-width: 545px;
        }
    }
}
@include respond('tab-port') {
    .testimonial {
        .section-head {
            p {
                display: none;
            }
        }
    }
}

.newsletter-wrapper {
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        background-color: rgba(59, 47, 74, 0.82);
        width: 100%;
        height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
    }
    &.style-2 {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
        border-radius: 25px;
        overflow: hidden;
        &:after {
            background-color: var(--secondary);
            opacity: 0.8;
        }
        @media screen and (max-width: 1360px) {
            max-width: 100%;
            margin-bottom: 0;
            border-radius: 0;
        }
    }

    .subscride-inner {
        .section-head {
            @media only screen and (max-width: 1024px) {
                margin-right: 0px;
            }
            @include respond('phone') {
                .title {
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }
        }

        .form-group {
            .input-group {
                .form-control {
                    height: 72px;
                    border: 1px solid $white;
                    border-radius: var(--border-radius-base) !important;
                    &::placeholder {
                        color: $white;
                    }
                }
                .input-group-addon {
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    z-index: 9;
                    transform: translateY(-50%);
                    .btn {
                        height: 60px;
                        width: 120px;
                        text-align: center;
                        span {
                            display: block;
                        }
                        i {
                            display: none;
                        }
                    }
                }
            }
        }
        @include respond('phone') {
            .form-group {
                .input-group {
                    margin-bottom: 0;
                    .form-control {
                        height: 64px;
                    }
                    .input-group-addon {
                        .btn {
                            height: 52px;
                            width: 52px;
                            text-align: center;
                            display: block;
                            padding: 0;
                            span {
                                display: none;
                            }
                            i {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
.history-wrapper {
    .info-right-history {
        padding-right: 90px;
        .section-head {
            p {
                margin-bottom: 12px;
            }
        }
    }
    //Responsive
    @media screen and (max-width: 1400px) {
        .info-right-history {
            padding-right: 38px;
        }
    }
    @media screen and (max-width: 1200px) {
        .info-right-history {
            padding-right: 0px;
        }
    }
    @media screen and (max-width: 1200px) {
        .info-right-history {
            p {
                font-size: 15px;
            }
        }
    }
}

.top-space {
    margin-top: 138px;
    //Responsive
    @media screen and (max-width: 1200px) {
        margin-top: 70px;
    }
}

.facility {
    .media {
        width: 750px;
        height: 580px;
        position: relative;
        left: -372px;
        margin: 0 0 70px 0;
        transform: skewX(1deg);
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 25px;
            transform: rotate(8deg);
            background-color: var(--primary);
        }
        img {
            width: 100%;
            border-radius: 0 25px 25px 0;
            position: absolute;
        }
    }
    .text {
        margin-bottom: 7px;
        color: #a5a5a5;
    }
    a {
        margin-top: 40px;
    }
    //Responsive
    @media screen and (max-width: 1600px) {
        .section-head {
            .title {
                font-size: 3rem !important;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .section-head {
            .title {
                font-size: 2.5rem !important;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .section-head {
            margin-bottom: 20px;
            .title {
                font-size: 2rem !important;
            }
            p {
                font-size: 14px;
            }
        }
        a {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 991px) {
        .section-head {
            .title {
                font-size: 1.75rem !important;
            }
        }
    }
    @media screen and (max-width: 600px) {
        a {
            display: none;
        }
    }
    @media screen and (max-width: 360px) {
        .section-head {
            .title {
                font-size: 1.6875rem !important;
            }
        }
    }
}
.book-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond('tab-port') {
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .testimonial {
        .section-head {
            p {
                display: none;
            }
        }
    }
}
.circle {
    position: absolute;
    width: 330px;
    height: 330px;
    background-color: #f5f5f5;
    left: calc(50% - 146px + 315px);
    border-radius: 100%;
    top: -174px;
    z-index: -1;
    &.style-1 {
        background-color: #f5f5f5;
    }
    &.style-2 {
        background-color: #191552;
    }
}
//Flash-Sale Section

.coundown-wrapper {
    .countdown {
        border: 1px solid #f0eeff;
        border-radius: 12px;
        max-width: 505px;
        margin: auto;
        padding: 10px 0 20px;
        margin-bottom: 80px;
    }
}

.map-iframe {
    //Responsive
    @media screen and (max-width: 1400px) {
        margin: 0;
    }
}
.location {
    margin-top: 80px;
    iframe {
        height: 350px;
    }
    @include respond('tab-port') {
        margin-top: 40px;
    }
}
.inner-text .title {
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 10px;
}

///css
.i-false:after {
    content: none !important;
}
