// OWL SLIDER ================
// NEXT PRE BTN
.owl-none .owl-nav {
    display: none;
}
.owl-nav {
    button {
        &.owl-prev,
        &.owl-next {
            cursor: pointer;
            transition: all 0.5s;
            display: inline-block;
            font-size: 24px !important;
            font-weight: 500 !important;
        }
    }
}
.owl-theme {
    .owl-nav,
    .owl-dots {
        margin-top: 20px;
        text-align: center;
    }
    .owl-nav .disabled {
        opacity: 0.5;
        cursor: default;
    }
    .owl-dots {
        text-align: center;

        .owl-dot {
            display: inline-block;
            span {
                width: 10px;
                height: 10px;
                margin: 5px 7px;
                background: #d6d6d6;
                display: block;
                -webkit-backface-visibility: visible;
                -webkit-transition: opacity 200ms ease;
                -moz-transition: opacity 200ms ease;
                -ms-transition: opacity 200ms ease;
                -o-transition: opacity 200ms ease;
                transition: opacity 200ms ease;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                border-radius: 30px;
            }
            &:hover,
            &.active {
                span {
                    background: #869791;
                }
            }
        }
    }

    // Owl Dots 1
    &.owl-dots-1 {
        .owl-dots .owl-dot {
            span {
                background-color: #dadada;
                width: 15px;
                height: 15px;
                margin: 5px;
            }
            &:hover,
            &.active {
                span {
                    background-color: var(--primary);
                }
            }
        }
    }
    &.owl-dots-2 {
        .owl-dots {
            margin-top: 40px;
            position: unset;
            right: auto;
            text-align: center;
            top: auto;
            transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            width: auto;
            .owl-dot {
                display: inline-block;
                margin: 4px;
                span {
                    height: 10px;
                    width: 10px;
                    margin: 0;
                    border-radius: 0;
                }
                &:hover,
                &.active {
                    span {
                        background-color: var(--primary);
                    }
                }
            }
        }
    }
    &.owl-dots-3 {
        .owl-dots {
            margin-top: 40px;
            position: unset;
            display: block;
            right: auto;
            text-align: center;
            top: auto;
            transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            width: auto;
            .owl-dot {
                display: inline-block;
                margin: 4px;
                span {
                    height: 8px;
                    width: 8px;
                    margin: 0;
                    background-color: #898c90;
                    border-radius: 8px;
                }
                &:hover,
                &.active {
                    span {
                        background-color: var(--primary);
                    }
                }
            }
        }
    }
}
// OWL BUTTON TOP TO CENTER
.owl-btn-top-c .owl-nav {
    text-align: center;
}

// OWL BUTTON TOP TO LEFT
.owl-btn-top-l .owl-nav {
    text-align: left;

    .owl-prev {
        margin-left: 0;
    }
}
// OWL BUTTON TOP TO RIGHT
.owl-btn-top-r .owl-nav {
    position: absolute;
    top: -125px;
    right: 0;
    margin: 0;

    .owl-next {
        margin-right: 0;
    }
}
// OWL BUTTON TOP TO LEFT & RIGHT
.owl-btn-top-lr .owl-nav {
    text-align: left;
    .owl-next {
        margin-right: 0;
        float: right;
    }
}
.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
    padding: 40px 8px;
}
// OWL BUTTON < > CENTER TO LEFT & RIGHT
.owl-btn-center {
    .owl-nav {
        justify-content: center;
    }
}
.owl-btn-center-lr {
    .owl-prev,
    .owl-next {
        position: absolute;
        left: 0;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        cursor: pointer;
    }
    .owl-prev {
        left: -110px;
    }
    .owl-next {
        left: auto;
        right: -110px;
    }
    @include respond('laptop') {
        .owl-prev {
            left: -40px;
        }
        .owl-next {
            right: -40px;
        }
    }
    @include respond('tab-land') {
        .owl-prev {
            left: -20px;
        }
        .owl-next {
            right: -20px;
        }
    }
}
.owl-carousel .owl-item img {
    transform-style: inherit;
}

// OWL BUTTON STYLE
.owl-btn-1 {
    .owl-nav {
        button {
            &.owl-prev,
            &.owl-next {
                border-radius: 3px;
                line-height: 40px;
                margin: 0 20px !important;
                padding: 0;
                text-align: center;
                color: var(--primary) !important;
                font-size: 35px;
                height: auto;
                width: auto;
                background-color: transparent;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    font-family: $font-family-title;
                    font-size: 15px;
                    line-height: 15px;
                    letter-spacing: 3px;
                    position: absolute;
                    font-weight: 400;
                    top: 8px;
                    color: $dark;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0);
                    color: #000;
                    opacity: 1;
                }
                @include respond('tab-port') {
                    margin: 0 10px !important;
                }
            }
            &.owl-prev {
                padding: 0 30px 0 0px !important;
                &:before {
                    content: 'PREV';
                    right: 0;
                }
            }
            &.owl-next {
                padding: 0 0 0 30px !important;
                &:before {
                    content: 'NEXT';
                    left: 0;
                }
            }
        }
    }
    &.btn-white {
        .owl-nav {
            .owl-prev,
            .owl-next {
                color: #fff;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
.owl-btn-2 {
    &.owl-btn-rounded {
        .owl-nav {
            .owl-prev,
            .owl-next {
                border-radius: 8px;
            }
        }
    }
    .owl-nav {
        .owl-prev,
        .owl-next {
            height: 60px;
            line-height: 60px;
            margin: 0 10px !important;
            padding: 0;
            text-align: center;
            width: 60px;
            box-shadow: 0 4px 15px var(--rgba-primary-2);
            @include respond('phone') {
                height: 40px;
                line-height: 40px;
                width: 40px;
                font-size: 18px !important;
            }
        }
    }
}
.owl-btn-3 {
    .owl-nav {
        .owl-prev,
        .owl-next {
            border-radius: 50px;
            height: 45px;
            line-height: 45px;
            margin: 0 10px !important;
            padding: 0;
            text-align: center;
            width: 45px;
        }
    }
}

.owl-btn-primary {
    .owl-nav {
        button {
            &.owl-prev,
            &.owl-next {
                background-color: var(--primary);
                color: $white;
                transition: all 0.5s;
                &:hover {
                    background-color: $white;
                    color: var(--primary);
                }
            }
        }
    }
}
.owl-btn-white {
    .owl-prev,
    .owl-next {
        background-color: $white;
        color: var(--primary);
        transition: all 0.5s;
        &:hover {
            background-color: var(--primary);
            color: $white;
        }
    }
}

// OWL-DOTS
.owl-dots-none .owl-dots {
    display: none;
}
.owl-dots-white-full,
.owl-dots-black-full,
.owl-dots-primary-full {
    .owl-dots {
        .owl-dot {
            span {
                @include transitionMedium;
                margin: 4px;
            }
            &:active,
            &.active {
                span {
                    opacity: 1;
                    background-color: var(--primary);
                }
            }
        }
    }
}
.owl-dots-white-big,
.owl-dots-black-big,
.owl-dots-primary-big {
    .owl-dots {
        .owl-dot {
            span {
                @include transitionMedium;
                margin: 4px;
            }
            &:active {
                span {
                    width: 14px;
                    height: 14px;
                    opacity: 1;
                    margin: 0px 4px 2px 4px;
                }
            }
        }
    }
}
.owl-dots-primary-full .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
    background-color: var(--primary);
}
.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span {
    background-color: #333;
}
.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-white-big .owl-dots .owl-dot span {
    background-color: #f5f5f5;
}

/* owl num count */
.owl-num-count {
    .owl-dots {
        text-align: right;
        counter-reset: li;
        justify-content: center;
        display: flex;
        .owl-dot {
            padding: 5px 2px !important;
            cursor: pointer;
            font-size: 18px;
            position: relative;
            span {
                width: 20px;
                height: 5px;
                border-radius: 10px;
                transition: all 0.5s;
                background-color: transparent;
            }
            &:before {
                content: '0' counter(li, decimal);
                counter-increment: li;
                font-weight: 600;
                color: #777777;
                font-size: 18px;
            }
            &.active {
                &:before {
                    color: var(--primary);
                }
                span {
                    background-color: var(--primary);
                }
            }
        }
    }
}
