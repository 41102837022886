/*Blog single */
.blog-single {
    margin-bottom: 3.125rem;

    .dz-title {
        margin-bottom: 1.25rem;
    }
    .dz-post-meta {
        margin-bottom: 1.25rem;
    }
    .dz-post-text {
        margin-top: 1.25rem;
        padding-bottom: 15px;
        &:after {
            content: '';
            display: block;
            width: 100%;
            clear: both;
        }
    }
    .dz-post-tags {
        margin-top: 1.25rem;
    }
    .dz-post-media {
        border-radius: 0.25rem;
    }
    &.dz-blog .post-tags {
        margin-bottom: 0;
    }
    &.style-1 {
        box-shadow: unset;
        border-radius: 0;
        overflow: unset;
        margin-bottom: 60px;
        .dz-info {
            .dz-meta {
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                margin-bottom: 10px;
                border: 0;

                &.meta-bottom {
                    border-top: 1px solid #e1e1f0 !important;
                    border-bottom: 1px solid #e1e1f0 !important;
                    padding: 15px 0;
                    margin-top: 0;
                }
            }
        }
    }
    &.style-1 {
        margin-bottom: 60px;
        background: transparent;
        border-radius: 0;
        @include respond('tab-port') {
            margin-bottom: 30px;
        }
        .meta-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
        }
        @include respond('tab-land') {
            .dz-title {
                font-size: 1.75rem;
            }
        }
        @include respond('phone-land') {
            .dz-title {
                font-size: 1.25rem;
            }
        }
        @include respond('phone') {
            .meta-bottom {
                display: block;
                .dz-social-icon {
                    margin-top: 10px;
                }
            }
        }
        .dz-media + .dz-info {
            padding: 30px 0 0 0 !important;
            border: 0;
            background: transparent;
        }
    }
}

.dz-post-text {
    a {
        text-decoration: underline;
        box-shadow: inset 0 -10px 0 var(--rgba-primary-1);
    }
    .widget_archive li a,
    .wp-block-latest-posts li a,
    .wp-block-categories-list li a,
    .wp-block-archives-list li a,
    .widget_categories li a,
    blockquote a,
    .wp-block-button__link {
        box-shadow: none;
        text-decoration: none;
    }
}
.post-header {
    position: relative;
    overflow: hidden;
    @include respond('phone') {
        margin-bottom: 1.25rem;
    }
    .dz-media {
        img {
            min-height: 250px;
            object-fit: cover;
        }
    }
    .dz-info {
        position: absolute;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&0+1,0.6+100 */
        background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 1%,
            rgba(0, 0, 0, 0.6) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 1%,
            rgba(0, 0, 0, 0.6) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 1%,
            rgba(0, 0, 0, 0.6) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); /* IE6-9 */
        position: absolute;
        bottom: 0;
        text-align: center;
        padding: 100px 30px 30px 30px !important;
        width: 100%;

        @include respond('phone') {
            padding: 40px 15px 15px 15px !important;
        }
        .dz-title {
            color: #fff;
            @include respond('phone') {
                font-size: 1.125rem;
            }
        }
        .dz-meta > ul {
            justify-content: center;
            & > li {
                color: #fff;
            }
        }
    }
}
.post-link-in {
    padding: 15px 50px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    left: 0;
    @include transitionMedium;
    &:hover {
        background: var(--primary);
        color: #fff;
    }
}
.side-bar {
    padding-left: 20px;
    @include respond('tab-port') {
        padding-left: 0;
        padding-right: 0;
    }
    &.left {
        padding-left: 0;
        padding-right: 20px;
        @include respond('tab-port') {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
// WP DEFAULT ==============
// > ALIGN NONE
.alignnone {
    margin: 0.3125rem 0 1.563rem 0;

    &img,
    &.wp-caption,
    &.is-resized {
        margin: 0.3125rem 0 1.563rem 0;
    }
}
// > ALIGN CENTER
.aligncenter {
    display: block;
    margin: 0.3125rem auto 0.938rem;
    text-align: center;
    &img,
    &.wp-caption,
    &.is-resized {
        display: block;
        margin: 0.3125rem auto 0.938rem;
        text-align: center;
    }
}
// > ALIGN RIGHT
.alignright {
    float: right;
    margin: 0.3125rem 0 1.563rem 1.563rem;
    &img,
    &.wp-caption,
    &.is-resized {
        margin: 0.3125rem 0 1.563rem 1.563rem;
        float: right;
    }
}
// > ALIGN LEFT
.alignleft {
    float: left;
    margin: 0.3125rem 1.563rem 1.563rem 0;
    &img,
    &.wp-caption,
    &.is-resized {
        margin: 0.3125rem 1.563rem 1.563rem 0;
        float: left;
    }
}

.wp-caption {
    max-width: 100%;
    text-align: center;
    img[class*='wp-image-'] {
        display: block;
        margin: 0;
    }
    img {
        border: 0 none;
        height: auto;
        margin: 0;
        max-width: 100%;
        padding: 0;
        width: auto;
    }
    p.wp-caption-text {
        font-size: 0.813rem;
        line-height: 1.125rem;
        margin: 0;
        padding: 0.625rem 0;
        text-align: left;
    }
}
// BLOCKQUOTE
.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
    padding: 1.25rem 2rem 1.25rem 3rem;
    font-size: 0.938rem;
    color: var(--secondary);
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    line-height: 1.875rem;
    position: relative;
    clear: both;
    font-weight: 700;
    z-index: 1;
    border: 0;

    @include respond('tab-port') {
        padding: 1.25rem 1.25rem 0.938rem 1.25rem;
        font-size: 0.813rem;
    }
    cite {
        font-style: normal;
        position: relative;
        display: block;
        margin-top: -0.3125rem;
        font-weight: 600;
        color: var(--primary);
        &:before {
            content: '';
            margin-right: 0.625rem;
            width: 15px;
            height: 2px;
            background: var(--primary);
            display: inline-block;
            vertical-align: middle;
        }
    }
    b,
    strong,
    .strong {
        color: inherit;
    }
    &:after {
        content: '';
        width: 6px;
        border-radius: 4px;
        height: 100%;
        left: 0;
        top: 0;
        background: var(--primary);
        position: absolute;
        box-shadow: 0 0 30px 0 var(--rgba-primary-2);
    }

    &:before {
        content: '';
        z-index: -1;
        background-size: cover;
        left: 20px;
        background-image: var(--quote);
        background-position: center right;
        width: 90px;
        padding: 0;
        opacity: 0.1;
        background-repeat: no-repeat;
        height: 90px;
        top: 0px;
        position: absolute;
    }
    &.wp-block-pullquote.alignleft {
        margin: 0rem 1.563rem 1.563rem 0rem;
    }
    &.wp-block-pullquote.alignright {
        margin: 0rem 0 1.563rem 1.563rem;
    }
}
blockquote.wp-block-pullquote:not(.is-style-solid-color) {
    background: #5608e0;
}
.wp-caption-text {
    font-size: 0.875rem;
    line-height: 1.3;
}
.dz-blog.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
    font-size: 1.5rem;
    line-height: 1.5;
    color: var(--secondary);
    margin-bottom: 1rem;
    font-weight: 700;
    font-style: italic;
    @include respond('tab-port') {
        font-size: 1.125rem;
    }
}
.dz-page-text {
    margin-bottom: 60px;
}
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto;
}

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-blog.blog-single,
.dz-blog.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
}
#comment-list {
    @include respond('tab-port') {
        margin-bottom: 50px;
    }
    .dz-page-text {
        margin-bottom: 0;
    }
}
.dz-blog.blog-single.sidebar + #comment-list {
    padding: 0;
    max-width: 100%;
}
.max-container {
    max-width: 93.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3.4375rem;
    padding-right: 3.4375rem;
}
.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
    padding: 0;
}
.dz-page-text.sidebar {
    width: 100%;
    padding: 0;
    max-width: 100%;
}
.dz-blog.blog-single .dz-info {
    padding: 0;
}
.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-blog.blog-single .dz-post-text > .wp-block-gallery,
.dz-blog.blog-single .dz-post-text > ol,
.dz-blog.blog-single .dz-post-text > ul {
    list-style: none;
    margin-bottom: 1.875rem;
}
.dz-page-text > ol,
.dz-page-text > ul,
.dz-blog.blog-single .dz-post-text > ol,
.dz-blog.blog-single .dz-post-text > ul,
.dz-blog.blog-single .dz-post-text.text > ol,
.dz-blog.blog-single .dz-post-text.text > ul {
    padding-left: 1.25rem;
    ol,
    ul {
        padding-left: 1.563rem;
    }
}
.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-blog.blog-single .dz-post-text > ol li,
.dz-blog.blog-single .dz-post-text > ul li,
.dz-blog.blog-single .dz-post-text > ol li,
.dz-blog.blog-single .dz-post-text > ul li,
.dz-blog.blog-single .dz-post-text.text > ol li,
.dz-blog.blog-single .dz-post-text.text > ul li {
    padding: 0.5rem 0.5rem;
    position: relative;
    list-style: inherit;
}
.dz-page-text > .wp-block-gallery,
.dz-blog.blog-single .dz-post-text > .wp-block-gallery,
.dz-blog.blog-single .dz-post-text.text > .wp-block-gallery {
    padding: 0;
    list-style: none;
}
.dz-page-text > .wp-block-gallery li,
.dz-blog.blog-single .dz-post-text > .wp-block-gallery li,
.dz-blog.blog-single .dz-post-text.text > .wp-block-gallery li {
    padding: 0;
    list-style: none;
    margin: 0.25rem;
}
.dz-page-text > .wp-block-gallery li:before,
.dz-blog.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-blog.blog-single .dz-post-text.text > .wp-block-gallery li:before {
    content: none;
}
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    margin: 0 0.5rem 0.5rem 0;
}
.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 0.5rem;
}
.content-spacious-full .wp-block-gallery {
    margin-left: calc(-12.5rem - (0.313rem / 2));
    margin-right: calc(-12.5rem - (0.313rem / 2));
    width: calc(100% + 25rem + 0.313rem);
    max-width: initial;
}
.alignfull,
.alignwide {
    margin-left: calc(-6.25rem - (0.313rem / 2));
    margin-right: calc(-6.25rem - (0.313rem / 2));
    width: calc(100% + 12.5rem + 0.313rem);
    max-width: initial;
}
.alignfull,
.alignfullwide {
    margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px) / 2));
    width: 100vw;
    max-width: 100vw;
    box-sizing: border-box;
}
.dz-page-text h1,
.dz-post-text h1,
.thm-unit-test .dz-post-text h1 {
    margin-bottom: 1.563rem;
    font-weight: 700;
}
.dz-page-text h2,
.dz-post-text h2,
.thm-unit-test .dz-post-text h2 {
    margin-bottom: 1.563rem;
    font-weight: 700;
}
.dz-page-text h3,
.dz-post-text h3,
.thm-unit-test .dz-post-text h3 {
    margin-bottom: 1.25rem;
    font-weight: 700;
}
.dz-page-text h4,
.dz-post-text h4,
.thm-unit-test .dz-post-text h4 {
    margin-bottom: 0.938rem;
    font-weight: 600;
}
.dz-page-text h5,
.dz-post-text h5,
.thm-unit-test .dz-post-text h5 {
    margin-bottom: 0.938rem;
}
.dz-page-text h6,
.dz-post-text h6,
.thm-unit-test .dz-post-text h6 {
    margin-bottom: 0.625rem;
}
.wp-block-code,
pre.wp-block-code,
pre {
    margin: 0 0 1.563rem;
    padding: 1.25rem;
    color: #fff;
    background-color: #212326;
    white-space: pre;
    font-size: 0.938rem;
    border-radius: 0.1875rem;
}
.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-blog.blog-single .dz-post-text,
.dz-blog.blog-single .dz-post-text ul li,
.dz-blog.blog-single .dz-post-text ol li,
.dz-blog.blog-single
    .dz-blog.blog-single
    .dz-post-text
    p:not(.has-text-color):not(.has-text-align-center):not(
        .has-text-align-left
    ):not(.has-text-align-right) {
    font-size: 1.125rem;
    line-height: 1.7;
}
.wp-block-cover,
.wp-block-cover-image {
    color: #fff;
}
.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
    margin-bottom: 0;
}
.wp-block-quote.has-text-align-right {
    border-right: 0;
}
.dz-page-text ul li,
.dz-page-text ol li,
.dz-blog.blog-single .dz-post-text ul li,
.dz-blog.blog-single .dz-post-text ol li {
    position: relative;
}
.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-blog.blog-single .dz-post-text ul > li:before,
.dz-blog.blog-single .dz-post-text ol > li:before {
    content: '';
    display: inline-block;
    width: 0.375rem;
    height: 0.375rem;
    background: $dark;
    left: -0.938rem;
    position: absolute;
    top: 1.25rem;
}
.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-blog.blog-single .dz-post-text ul > li li:before,
.dz-blog.blog-single .dz-post-text ol > li li:before {
    content: none;
}
.dz-page-text a,
.dz-blog.blog-single .dz-post-text a {
}
.dz-page-text p,
.dz-blog.blog-single .dz-post-text p {
    margin-bottom: 1.65em;
}
//.dz-page-text .wp-block-gallery,
//.dz-blog.blog-single .dz-post-text .wp-block-gallery {
//    margin-left: calc(-11.875rem - (0.625rem/2));
//    margin-right: calc(-11.875rem - (0.625rem/2));
//    width: calc(100% + 23.75rem + 0.625rem);
//    max-width: initial;
//}
.paginate-links {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 4.5rem 0;
}
.paginate-links a,
.paginate-links > span {
    margin: 0 0 0 0.625rem;
    position: relative;
    border: 0rem solid #6cc000;
    color: #1f2471;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    background: #e0e0e0;
    border-radius: 0.1875rem;
}
.wp-block-columns {
    margin-bottom: 0;
}
/* Gutenberg Gallery */
.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 0.375rem 0.375rem 0;
    position: relative;
}
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    height: 100%;
    margin: 0;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto;
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.3) 60%,
        transparent
    );
    bottom: 0;
    color: #fff;
    font-size: 0.813rem;
    max-height: 100%;
    overflow: auto;
    padding: 2.5rem 0.625rem 0.3125rem;
    position: absolute;
    text-align: center;
    width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        -o-object-fit: cover;
        flex: 1;
        height: 100%;
        object-fit: cover;
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    margin-right: 0;
    width: 100%;
}

@media only screen and (max-width: 64rem) {
    .alignwide {
        margin-left: 0;
        margin-right: 0;
        width: 100% !important;
        max-width: 100% !important;
    }
    .alignwide .alignleft img {
        width: 100%;
    }

    .alignwide .alignleft {
        margin: 0.3125rem 0rem 1.563rem 0;
        float: none;
    }
}
@media (min-width: 37.5rem) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        margin-right: 0.375rem;
        width: calc(33.33333% - 0.666rem);
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        margin-right: 0.375rem;
        width: calc(25% - 0.75rem);
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        margin-right: 0.375rem;
        width: calc(20% - 0.8rem);
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        margin-right: 0.375rem;
        width: calc(16.66667% - 0.833rem);
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        margin-right: 0.375rem;
        width: calc(14.28571% - 0.857rem);
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        margin-right: 0.375rem;
        width: calc(12.5% - 0.875rem);
    }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0;
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%;
}
.wp-block-image.alignfullwide img {
    border-radius: 0;
}
.wp-block-image img {
    border-radius: 0.625rem;
}
.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
}
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 19.0625rem;
    width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center;
}
.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-blog.sidebar .alignfullwide,
.blog-single.dz-blog.sidebar .alignwide,
.blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: initial;
}
.blog-overlap {
    background: #fff;
    margin-top: -8.75rem;
    padding: 1.25rem 1.25rem 0;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
}
.blog-single.dz-blog.sidebar {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    font-size: 1rem;
    margin-bottom: 1.875rem;
}
.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    margin-top: 0.625rem;
}
.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
    background-color: #fafafa;
}
.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
    padding: 0.625rem 0.938rem;
    border: 0.0625rem solid #e4e4e4;
    border-right: 0;
    border-left: 0;
}

.wp-block-media-text {
    margin-bottom: 30px;
    .wp-block-media-text__content {
        padding: 0 1.875rem;
    }
}
.wp-block-column ol li:before,
.wp-block-column ul li:before {
    content: none !important;
}
.wp-block-pullquote {
    padding: 1em 0;
    border-top: 0;
    border-bottom: 0;
    cite {
        color: #fff;
    }
    &.alignright,
    &.alignleft {
        padding: 0;
        border-top: 0;
        border-bottom: 0;
        blockquote {
            margin: 0;
        }
    }
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2 {
    color: #fff;
}
.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
    box-shadow: none;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
}
.dz-post-text .wp-block-calendar a {
    box-shadow: none !important;
    text-decoration: unset;
}
.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
    margin-bottom: 2.5rem;
}

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
    top: 1.875rem;
}
.wp-block-quote.is-style-large cite:before {
    display: none;
}
/* Theme Unit Test */
.post-password-form {
    label {
        font-size: 1rem;
    }
    input[type='submit'] {
        padding: 0.625rem 1.563rem;
        background-color: var(--primary);
        border: none;
        height: 2.8125rem;
        font-weight: 600;
        font-size: 0.875rem;
        outline: none;
    }
    input[type='submit']:hover {
        background-color: var(--primary);
    }
    input[type='password'] {
        height: 2.8125rem;
        border: 0.0625rem solid #ced4da;
    }
}

// WP BLOCK SEARCH
.wp-block-search {
    .wp-block-search__button {
        background: var(--primary);
        border: 0;
        color: #fff;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0.625rem 1.563rem;
        @include transitionSlow;
    }
    .wp-block-search__input {
        border: 0.0625rem solid #e1e6eb;
        height: 2.8125rem;
        padding: 0.625rem 1.25rem;
        font-size: 0.875rem;
        outline: none;
    }
}

.wp-block-tag-cloud a {
    padding: 0.5rem 0.625rem;
    background: var(--primary);
    font-size: 0.75rem;
    display: inline-block;
    margin: 0 0 0.3125rem;
    color: #fff;
    font-weight: 600;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    text-decoration: none;
    box-shadow: none;

    &:hover {
        background: var(--primary-hover);
    }
}
// Comments Avatar
.wp-block-latest-comments {
    .avatar {
        width: 3.4375rem;
        height: 3.4375rem;
        border-radius: 3.4375rem;
    }
    .wp-block-latest-comments__comment {
        .wp-block-latest-comments__comment-excerpt {
            margin-left: 4.375rem;
        }
        .wp-block-latest-comments__comment-meta {
            color: #1f2471;
            font-family: 'Poppins', sans-serif;
            font-size: 1.063rem;
            font-weight: 500;
            line-height: 1.7;
            margin-bottom: 0.625rem;
            a {
                color: #1f2471;
                box-shadow: none;
                text-decoration: none;
            }
        }
    }
    img {
        + {
            article {
                .wp-block-latest-comments__comment {
                    .wp-block-latest-comments__comment-meta {
                        margin-left: 4.375rem;
                    }
                }
            }
        }
    }
    padding: 0 !important;
    .wp-block-latest-comments__comment-meta {
        time {
            display: block;
            width: 100%;
            color: #9fa1a4;
            font-size: 0.813rem;
            font-weight: 400;
        }
    }
    .wp-block-latest-comments__comment-excerpt {
        p {
            font-size: 1rem !important;
            line-height: 1.5 !important;
            margin-bottom: 0.625rem !important;
        }
    }
    li {
        &:before {
            content: none !important;
        }
        padding: 0 !important;
        border-bottom: 0.0625rem solid #eee;
        padding-bottom: 0.625rem !important;
    }
}
.wp-block-latest-comments__comment-avatar {
    width: 3.4375rem;
    height: 3.4375rem;
    border-radius: 3.4375rem;
}
.comment-content.dz-page-text {
    max-width: 100%;
    margin-bottom: 0;
}
// WP BLOCK RSS
.wp-block-rss {
    padding: 0 !important;
    .wp-block-rss__item {
        padding: 0.3125rem 0 !important;
        border-bottom: 0.0625rem solid #eee;
        &:before {
            content: none !important;
        }
        a {
            font-family: 'Poppins', sans-serif;
            font-size: 1.125rem;
            box-shadow: unset !important;
            font-weight: 600;
            color: #1f2471;
            text-decoration: none;
        }
    }
}
@media only screen and (max-width: 75rem) {
    .dz-page-text .wp-block-gallery,
    .dz-blog.blog-single .dz-post-text .wp-block-gallery {
        margin-left: calc(-6.25rem - (0.625rem / 2));
        margin-right: calc(-6.25rem - (0.625rem / 2));
        width: calc(100% + 12.5rem + 0.625rem);
    }
}
@media only screen and (max-width: 61.9375rem) {
    .admin-bar .mo-left .header-nav {
        top: 1.875rem;
        height: calc(100vh - 1.875rem) !important;
    }

    .dz-page-text .wp-block-gallery,
    .dz-blog.blog-single .dz-post-text .wp-block-gallery {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    .dz-page-text,
    .dz-page-text ul li,
    .dz-page-text ol li,
    .dz-page-text p,
    .dz-blog.blog-single .dz-post-text,
    .dz-blog.blog-single .dz-post-text ul li,
    .dz-blog.blog-single .dz-post-text ol li,
    .dz-blog.blog-single .dz-post-text p {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 48.875rem) {
    .admin-bar .is-fixed .main-bar {
        top: 2.8125rem;
    }
    .admin-bar .mo-left .header-nav {
        top: 2.8125rem;
        height: calc(100vh - 2.812rem) !important;
    }
    .wp-block-media-text {
        display: block;
    }
    .wp-block-media-text .wp-block-media-text__media {
        margin-bottom: 0.938rem;
    }
    .wp-block-media-text .wp-block-media-text__content {
        padding: 0;
    }
}
@media only screen and (max-width: 37.5rem) {
    .admin-bar .is-fixed .main-bar {
        top: 0;
    }
    .admin-bar .mo-left .header-nav {
        top: 2.8125rem;
        height: calc(100vh - 2.812rem) !important;
    }
    .admin-bar .mo-left .is-fixed .header-nav {
        top: 0;
        height: 100vh !important;
    }
}

// POST FOOTER
.post-footer {
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.938rem 0 0;

    .dz-meta {
        .tag-list {
            padding-bottom: 0;
            text-align: left;
            margin: 0;
            padding: 0;
            list-style: none;
            text-transform: capitalize;
            display: inline;
        }
        ul li {
            margin-right: 0.3125rem;
            padding: 0;
            display: inline-block;
            color: #333333;
            font-weight: 500;
            font-size: 0.938rem;
            font-style: italic;
        }
    }
    .post-tag a {
        text-transform: capitalize;
        font-size: 0.938rem;
        color: #999;
        background: transparent;
        padding: 0;
        border: 0;
        line-height: 0.875rem;
        font-weight: 500;
    }
}
.share-post {
    li {
        display: inline-block;
    }
    a {
        width: 2.1875rem;
        height: 2.1875rem;
        border-radius: 2.1875rem;
        border: 0.0625rem solid #eee;
        display: block;
        text-align: center;
        line-height: 2.0625rem;
        color: $dark;
    }
}

// comments-area
.comments-area,
.comment-respond,
.extra-blog {
    &.style-1 {
        .comments-title,
        .comment-reply-title,
        .blog-title {
            position: relative;
            padding-left: 20px;
            margin-bottom: 30px;
            @include respond('phone') {
                margin-bottom: 30px;
                padding-left: 15px;
                font-size: 22px;
            }
            &:before {
                content: '';
                height: 100%;
                width: 5px;
                border-radius: 10px;
                background-color: var(--primary);
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

//  .extra-blog
.extra-blog {
    margin-bottom: 60px;
    @include respond('tab-port') {
        margin-bottom: 20px;
    }
}
.comment-respond.style-1 .comment-reply-title {
    @include respond('phone') {
        margin-bottom: 20px;
    }
}
.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
    font-weight: 600;
}
.default-form {
    padding: 30px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: var(--border-radius-base);
}
.says,
.comment-meta {
    display: none;
}
// comments-area
// comments-area
.comments-area {
    .comment-list {
        margin-bottom: 60px;
        padding: 0;
        & > .comment {
            .comment-body {
                position: relative;
                padding: 2px 0 30px 100px;
                margin-bottom: 30px;
                min-height: 115px;
                border-bottom: 1px solid #e1e1f0;

                .comment-author {
                    .avatar {
                        position: absolute;
                        left: 0;
                        height: 85px;
                        width: 85px;
                        top: 0;
                    }
                    .fn {
                        font-family: $font-family-title;
                        font-size: 18px;
                        line-height: 18px;
                        color: var(--secondary);
                        font-weight: 600;
                        font-style: normal;
                        margin-bottom: 10px;
                        display: block;
                    }
                }
                p {
                    font-size: 15px;
                    margin-bottom: 10px;
                }
                .reply {
                    line-height: 1;
                    .comment-reply-link {
                        font-weight: 500;
                        text-transform: uppercase;
                        font-size: 14px;

                        i {
                            margin-right: 8px;
                        }
                    }
                }
            }
            & > .children {
                padding-left: 50px;
            }
        }
        .default-form {
            margin-bottom: 30px;
        }
    }

    &.style-1 {
        .comment-list {
            & > .comment {
                .comment-body {
                    .comment-author {
                        .avatar {
                            border-radius: 10px;
                        }
                    }
                }
                &:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    &.style-2 {
        .comment-list {
            & > .comment {
                border-bottom: 1px solid $border-color;
                margin-bottom: 30px;
                .comment-body {
                    .comment-author {
                        .avatar {
                            border-radius: 50%;
                            box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
                            border: 5px solid $white;
                        }
                    }
                }
                &:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    &.style-3 {
        .comment-list {
            & > .comment {
                margin-bottom: 30px;
                .comment-body {
                    .comment-author {
                        .avatar {
                            border-radius: 5px;
                            box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
                            border: 5px solid $white;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.comment-reply-title a {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
}
.comment-respond {
    .comment-form {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        p {
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
            label {
                display: none;
            }
            &.comment-form-author input,
            &.comment-form-email input,
            &.comment-form-url input,
            textarea {
                width: 100%;
                height: 45px;
                border-radius: 4px !important;
                padding: 10px 20px;
                position: relative;
                font-size: 15px;
                border: 1px solid #b7b7b7;
            }
            &.comment-form-comment textarea {
                height: 120px !important;
            }
            &.form-submit {
                margin-bottom: 0;
            }
        }

        .comment-form-comment {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    &.style-1,
    &.style-3 {
        .comment-form {
            .comment-form-author,
            .comment-form-email,
            .comment-form-url {
                width: 50%;
            }
        }
    }
    &.style-2 {
        .comment-form {
            .comment-form-author,
            .comment-form-email {
                width: 50%;
            }
            .comment-form-url {
                width: 100%;
            }
        }
    }

    @include respond('phone') {
        .comment-form {
            .comment-form-author,
            .comment-form-email,
            .comment-form-url {
                width: 100% !important;
            }
        }
    }
}
.dz-page-text [class*='galleryid-'],
.dz-post-text [class*='galleryid-'] {
    clear: both;
    margin: 0 auto;
    overflow: hidden;
}
.gallery {
    .gallery-item {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding: 0.3125rem;
        position: relative;
        box-sizing: border-box;
        img {
            float: left;
            padding: 0 0rem;
            width: 100%;
            border: none !important;
        }
    }
    .gallery-caption {
        font-size: 0.813rem;
        color: #707070;
        display: block;
        font-family: 'Noto Sans', sans-serif;
        line-height: 1.5;
        padding: 0.5em 0;
        clear: both;
    }
}
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
    display: none;
}
.gallery-columns-2 .gallery-item {
    max-width: 50%;
}
.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
    max-width: 25%;
}
.gallery-columns-5 .gallery-item {
    max-width: 20%;
}
.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}
.gallery-icon img {
    margin: 0 auto;
}

.post-password-form {
    position: relative;
    clear: both;
    label {
        display: block;
        font-size: 1rem;
    }
    input[type='password'] {
        width: 100%;
        border: 0.0625rem solid #ebedf2;
        padding: 0.625rem 6.25rem 0.625rem 0.938rem;
        height: 2.8125rem;
        border: 0.0625rem solid #ced4da;
    }
    input[type='submit'] {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0.625rem 1.25rem;
        background: var(--primary);
        color: #fff;
        border: none;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.875rem;
        outline: none;
        height: 2.8125rem;
        &:hover {
            background: var(--primary-hover);
        }
    }
}
@include respond('tab-port') {
    .side-bar.sticky-top {
        position: unset;
    }
}
.blog-post-banner {
    min-height: 300px;
    padding-top: 70px;
    padding-bottom: 70px;
    .dz-title {
        color: #fff;
        font-size: 32px;
        margin-bottom: 20px;
    }
    .dz-meta {
        color: #fff;
        ul {
            display: flex;
            opacity: 0.7;
            li {
                margin-right: 20px;
                a {
                    color: #fff;
                }
            }
        }
    }
}
.post-outside {
    margin-top: -120px;
}
.single-post .main-bar {
    border-bottom: 1px solid #eee;
}
.single-post .site-header.header-transparent .main-bar {
    border-bottom: 0;
}
.dz-load-more i,
.loadmore-btn i {
    &:before {
        -webkit-animation: unset !important;
        -moz-animation: unset !important;
        animation: unset !important;
    }
}
#comment-list:empty + .paginate-links {
    margin-top: -30px;
}
/* .post-btn{
	display:flex;
	align-items:center;
	.next-post,
	.prev-post{
		max-width:50%;
		flex:0 0 50%;
		display:flex;
		align-items:center;
		
		img{
			width:80px;
			height:80px;
		}
		.title{
			flex:1;
		}
	}
	.next-post{
		
	}
	.prev-post{
		
	}
} */

/* Post Next Preview */
.post-btn {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #ededee;
    border-bottom: 1px solid #ededee;
    padding: 15px 0;
    margin: 50px 0px;
    position: relative;
    &:after {
        content: '';
        height: calc(100% - 30px);
        width: 1px;
        background: #ededee;
        position: absolute;
        left: 50%;
        top: 15px;
    }
    .prev-post,
    .next-post {
        max-width: 50%;
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        position: relative;
        img {
            height: 70px;
            width: 85px;
            border-radius: 6px;
            object-fit: cover;
            @include respond('phone-land') {
                display: none;
            }
        }

        .start,
        .end {
            color: #b5b5b5;
            cursor: not-allowed;
            width: 100%;
            padding: 0;
            margin-bottom: 0;
        }
        .title {
            @include respond('phone-land') {
                font-size: 14px;
            }
            a {
                display: block;
                margin-bottom: 5px;
            }
            .post-date {
                display: block;
                font-size: 13px;
                font-weight: 400;
                color: #666;
                @include respond('phone-land') {
                    font-size: 12px;
                }
            }
        }
    }
    .next-post {
        padding-right: 30px;
        padding-left: 10px;
        @include respond('phone-land') {
            padding-right: 20px;
        }
        .title {
            text-align: right;
            width: 100%;
            margin-bottom: 0;
        }
        .title + img {
            margin-left: 20px;
        }
        .end {
            text-align: right;
        }
        &:after {
            content: '\f105';
            font-family: 'FontAwesome';
            color: var(--primary);
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 30px;
            transform: translateY(-50%);
        }
    }
    .prev-post {
        padding-left: 30px;
        padding-right: 10px;
        @include respond('phone-land') {
            padding-left: 20px;
        }
        .title {
            margin-bottom: 0;
            width: 100%;
        }
        img + .title {
            padding: 0 20px;
        }
        &:after {
            content: '\f104';
            font-family: 'FontAwesome';
            color: var(--primary);
            position: absolute;
            left: 0;
            top: 50%;
            font-size: 30px;
            transform: translateY(-50%);
        }
    }
}
