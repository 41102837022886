@import "rsuite/dist/rsuite.css";
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium WebLight'), local('TitilliumWeb-Light'),
    url('http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff')
      format('woff');
}

* {
  font-family: var(--font-family-base);
}
.next,
.previous {
  cursor: pointer;
}

.previous {
  margin-right: 20px;
}

.next {
  margin-left: 20px;
}

.previous-style2 {
  margin-right: 0;
}

.next-style2 {
  margin-left: 0;
}

.btn-cart{
  font-size: 13px;
}
/**************************  Mobile  ***************************/

/* Hide Mobile Header */

@media screen and (max-width: 600px) {
  /* Change Ajouter Au Panier Size */
  .btn-cart {
    height: 60px;
    width: 90px;
    font-size: 10px;
  }
}


.recommended-slider-image-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommended-slider-image-container img {
  cursor: grab;
  transition: all 300ms ease;
  transform-origin: top center;
  /* height: 100%; */
}

.recommended-slider-image-container:hover img {
  transform: scale(1.05);
}

.newsletter-wrapper h2 span {
  color: var(--secondary);
}

.newsletter-wrapper .news-letter-button button {
  width: fit-content !important;
}

.newsletter-wrapper {
  padding: 100px 0 !important;
  filter: none;
}

.newsletter-wrapper::after {
  background-color: transparent;
}

.footer-insider {
  justify-content: space-between;
}

.icon-container:hover .icon-cell {
  animation: shake 1s;
}

.fay-section{
  padding: 40px 0;
}
.news-letter-text{
  color:white
}
.form-check-input:checked {
  background-color: var(--primary);
  border-color:  var(--primary);
}

.swiper-button-prev, .swiper-button-next{
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.pagination-align.style-2 .swiper-button-next::after,.pagination-align.style-1 .swiper-button-next::after,
.pagination-align.style-2 .swiper-button-prev::after, .pagination-align.style-1 .swiper-button-prev::after {
  content: '';
}
.swiper-pagination-bullet{
  border-radius: 100%;
  cursor: pointer;
}