.dz-blog {
    .post-tags {
        display: block !important;
        margin-bottom: 10px;
        a {
            margin-left: 8px;
            font-weight: 400;
            color: inherit;
            display: inline-block;

            &:hover {
                color: var(--primary);
            }
        }
        strong {
            font-weight: 700;
        }
    }
    &.no-image {
        padding: 30px;
        background: var(--rgba-primary-05);
    }
    .dz-meta {
        margin-bottom: 10px;
        & > ul {
            & > li {
                font-weight: 400;
                font-size: 15px;
                display: inline;
                margin-right: 20px;

                a {
                    color: inherit;
                }
                i {
                    font-size: 16px;
                    position: relative;
                    margin-right: 5px;
                    color: var(--primary);

                    @include respond('tab-land') {
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                    margin-right: 3px;
                }
                @include respond('tab-port') {
                    margin-right: 15px;
                }
                &.post-comment {
                    @include respond('phone') {
                        display: none;
                    }
                }
                &.post-category {
                    a {
                        margin-right: 0;
                        padding-right: 3px;
                        padding-left: 3px;
                    }
                }
                &.post-author {
                    a {
                        color: inherit;
                        margin-left: 3px;
                    }
                }
                &.post-share {
                    position: relative;
                    ul {
                        display: inline-block;
                        position: absolute;
                        right: 40px;
                        background-color: var(--primary);
                        box-shadow: -2px 9px 20px 0 rgba(0, 0, 0, 0.2);
                        display: flex;
                        border-radius: 4px;
                        transform: scale(0.8);
                        -moz-transform: scale(0.8);
                        -webkit-transform: scale(0.8);
                        -ms-transform: scale(0.8);
                        -o-transform: scale(0.8);
                        transition: all 0.5s;
                        -moz-transition: all 0.5s;
                        -webkit-transition: all 0.5s;
                        -ms-transition: all 0.5s;
                        -o-transition: all 0.5s;
                        z-index: 2;
                        opacity: 0;
                        padding: 5px;
                        visibility: hidden;
                        &:after {
                            content: '';
                            position: absolute;
                            right: -7px;
                            top: 50%;
                            background: var(--primary);
                            width: 8px;
                            height: 8px;
                            display: block;
                            transform: rotate(45deg) translateX(-50%);
                            -moz-transform: rotate(45deg) translateX(-50%);
                            -webkit-transform: rotate(45deg) translateX(-50%);
                            -ms-transform: rotate(45deg) translateX(-50%);
                            -o-transform: rotate(45deg) translateX(-50%);
                        }
                        li {
                            a {
                                width: 30px;
                                height: 30px;
                                display: block;
                                line-height: 30px;
                                text-align: center;
                                color: $white;
                            }
                        }
                    }
                    &:hover {
                        ul {
                            transform: scale(1);
                            -moz-transform: scale(1);
                            -webkit-transform: scale(1);
                            -ms-transform: scale(1);
                            -o-transform: scale(1);
                            right: 40px;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .dz-title {
        margin-bottom: 10px;
        line-height: 1.4;
        word-break: break-word;
    }
    .dz-media + .dz-info {
        padding-top: 30px;
    }
    &.blog-half .dz-info {
        padding-top: 0;
        align-self: center;
        flex: 1;
    }
    &.style-1 {
        border-radius: $border-radius;
        overflow: hidden;

        .dz-info {
            padding: 30px;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 6px;

            background: #fff;
            @include respond('phone') {
                padding: 15px;
            }
            .dz-meta {
                border-bottom: 1px solid $border-color;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
            .meta-bottom {
                border-top: 1px solid $border-color;
                border-bottom: 0;
                padding: 25px 0 0;
                margin: 20px 0 0;
                @include respond('phone') {
                    padding: 15px 0 0;
                }
            }
        }
        .dz-media + .dz-info {
            border-top: 0;
            border-radius: 0 0 6px 6px;
        }
    }
    &.blog-half.style-1 {
        display: flex;
        @include transitionMedium;

        &.blog-half-sm {
            .dz-media {
                width: 150px;
                min-width: 150px;

                img {
                    min-height: 180px;
                }
                @include respond('phone-land') {
                    width: 100%;
                    min-width: 100%;
                }
            }
        }

        .dz-media {
            width: 250px;
            min-width: 250px;
            img {
                height: 100%;
                //min-height: 240px;
                object-fit: cover;
            }
            a {
                height: 100%;
            }
        }
        .dz-info {
            border: 1px solid rgba(0, 0, 0, 0.125);
        }
        .dz-media + .dz-info {
            border-left: 0;
            border-radius: 0 6px 6px 0;
        }
        .dz-title {
            margin-bottom: 10px;
            line-height: 1.3;
        }
        p {
            margin-bottom: 20px;
            font-size: 15px;
            text-align: justify;
        }
        .dz-meta {
            margin-bottom: 0;
            line-height: 1;
        }
        @include respond('phone-land') {
            display: block;
            padding: 20px;
            .dz-media {
                width: 100%;
                min-width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        @media only screen and (max-width: 600px) {
            .dz-media {
                margin-bottom: 0;
                border-radius: 6px 6px 0 0;
            }
            .dz-info {
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
                border-radius: 0px 0px 6px 6px;
            }
        }
        @include respond('phone') {
            padding: 0px;
        }
    }
}
.dz-card.style-2 {
    margin-bottom: 10px;
    .dz-media {
        border-radius: 12px 12px 0 0;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            color: rgba(19, 16, 69, 0.87);
        }
    }
    .dz-info {
        border: 1px solid #f0f0f0;
        padding: 33px 32px 30px;
        border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
        @include respond('phone') {
            padding: 25px 15px;
        }
        .dz-title {
            margin-bottom: 5px;
        }
        .dz-tags {
            margin-bottom: 10px;
            li {
                display: inline-block;
                a {
                    font-size: 12px;
                    font-weight: 600;
                    padding: 4px 8px;
                    background-color: rgb(242, 242, 242);
                    border-radius: var(--border-radius-base);
                    color: var(--secondary);
                    &:hover {
                        background-color: var(--primary);
                        color: #fff;
                    }
                }
            }
        }
        .bookcard-footer {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            position: relative;
            .price-details {
                p {
                    font-size: 28px;
                    font-weight: 700;
                    margin: 0;
                    font-family: var(--font-family-title);

                    span {
                        font-size: 20px;
                        font-weight: var(--headings-font-weight);
                        color: #aaaaaa;
                        text-decoration: line-through;
                        margin-left: 7px;
                    }
                }
            }
        }
    }
}
.post-carousel.owl-theme {
    position: relative;
    .owl-dots {
        position: absolute;
        bottom: 20px;
        width: 100%;
        .owl-dot {
            span {
                background: transparent;
                width: 12px;
                height: 12px;
                border: 2px solid #fff;
            }
            &.active span {
                background: var(--primary);
                border: 2px solid var(--primary);
            }
        }
    }
    .owl-nav {
        margin: 0;
        .owl-prev,
        .owl-next {
            @include transitionMedium;
            background: rgba(0, 0, 0, 0.2);
            color: #fff;
            border-radius: 4px;
            font-size: 22px;
            &:hover {
                background: var(--primary);
            }
        }
    }
}
.post-video {
    position: relative;
    a {
        display: block;
        position: relative;
    }
    .post-video-icon {
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: #fff;
        @include translateZ50;
        @include transitionMedium;
        font-size: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        border-radius: 4px;
        &:hover {
            background: var(--primary);
            color: #fff;
        }
    }
    @include respond('phone') {
        .post-video-icon {
            width: 50px;
            height: 50px;
            font-size: 24px;
            padding-left: 2px;
        }
    }
}
.post-quote.style-1 {
    background: var(--rgba-primary-05);

    position: relative;
    .post-quote-icon {
        position: absolute;
        width: 50px;
        left: 40px;
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);

        svg {
            fill: var(--primary);
        }
    }
    .dz-title {
        margin-bottom: 0;
    }
    .dz-info {
        padding: 50px;
        padding-left: 120px;
    }
    @include respond('phone') {
        .post-quote-icon {
            left: 14px;
        }
        .dz-info {
            padding: 30px 30px 30px 70px;
        }
    }
}
@include respond('tab-port') {
    .dz-order-1 {
        order: 1;
        margin-top: 20px;
    }
}

.type-post.sticky,
.type-post.tag-sticky-2.post {
    position: relative;
    border: 0.375rem solid var(--primary);
    overflow: hidden;

    .sticky-icon {
        background: var(--primary);
        width: 3.125rem;
        height: 3.125rem;
        position: absolute;
        transform: rotate(45deg);
        top: -1.563rem;
        right: -1.563rem;
        z-index: 1;
        color: #fff;

        i {
            position: absolute;
            bottom: 0.1875rem;
            right: 1.125rem;
            transform: rotate(5deg);
            color: #fff;
            font-size: 1rem;
        }
    }
}
