@-webkit-keyframes upToDown {
    49% {
        -webkit-transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}
@-moz-keyframes upToDown {
    49% {
        -moz-transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}
@keyframes upToDown {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-webkit-keyframes upToRight {
    49% {
        -webkit-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}
@-moz-keyframes upToRight {
    49% {
        -moz-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}
@keyframes upToRight {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-webkit-keyframes leftToRight {
    49% {
        -webkit-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}
@-moz-keyframes leftToRight {
    49% {
        -moz-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}
@keyframes leftToRight {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes movedelement {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(10px, 10px);
        transform: translate(10px, 10px);
    }
    50% {
        -webkit-transform: translate(5px, 5px);
        transform: translate(5px, 5px);
    }
    75% {
        -webkit-transform: translate(10px, -5px);
        transform: translate(10px, -5px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement2 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(-10px, -10px);
        transform: translate(-10px, -10px);
    }
    50% {
        -webkit-transform: translate(-5px, -5px);
        transform: translate(-5px, -5px);
    }
    75% {
        -webkit-transform: translate(-10px, 5px);
        transform: translate(-10px, 5px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(15px, 15px);
        transform: translate(15px, 15px);
    }
    50% {
        -webkit-transform: translate(8px, 8px);
        transform: translate(8px, 8px);
    }
    75% {
        -webkit-transform: translate(15px, -8px);
        transform: translate(15px, -8px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
@keyframes movedelement4 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(-15px, -15px);
        transform: translate(-15px, -15px);
    }
    50% {
        -webkit-transform: translate(-8px, -8px);
        transform: translate(-8px, -8px);
    }
    75% {
        -webkit-transform: translate(-15px, 8px);
        transform: translate(-15px, 8px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement5 {
    0% {
        -webkit-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
    }
    25% {
        -webkit-transform: translate(10px, calc(-50% + 10px));
        transform: translate(10px, calc(-50% + 10px));
    }
    50% {
        -webkit-transform: translate(5px, calc(-50% + 5px));
        transform: translate(5px, calc(-50% + 5px));
    }
    75% {
        -webkit-transform: translate(10px, calc(-50% + -5px));
        transform: translate(10px, calc(-50% + -5px));
    }
    to {
        -webkit-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: translate(-50%, -50%) rotate(-45deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@-moz-keyframes spin2 {
    100% {
        -moz-transform: translate(-50%, -50%) rotate(45deg);
    }
}
@-webkit-keyframes spin2 {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
    }
}
@keyframes spin2 {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}
