$grid-columns: 12;

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($size / $columns);
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    @for $i from 1 through $grid-columns {
        .col-xxl-#{$i} {
            flex: 0 0 percentage($i / $grid-columns);
            max-width: percentage($i / $grid-columns);
        }
    }
}
