.dez-coming-soon {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    z-index: 1;
    background-color: var(--primary);
    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        mix-blend-mode: multiply;
    }

    .dzSubscribe {
        margin-top: 40px;
        margin-bottom: 70px;
        padding: 0 15px;
        p {
            font-size: 14px;
        }
        .ft-row {
            position: relative;
            .btn {
                position: absolute;
                padding: 0;
                height: 56px;
                width: 56px;
                line-height: 56px;
                border-radius: 56px;
                text-align: center;
                background: var(--primary);
                color: #fff;
                top: 5px;
                right: 5px;
            }
            .form-control {
                height: 66px;
                border-radius: 66px !important;
                padding: 10px 70px 10px 30px;
            }
        }
        @include respond('phone') {
            margin-top: 25px;
            margin-bottom: 30px;
        }
    }
}
.dez-coming-soon .container {
    position: relative;
}
.dez-coming-bx {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include respond('tab-port') {
        display: block;
        height: auto;
        padding-bottom: 30px;
    }
}

.countdown-timer {
    padding-bottom: 30px;
}
.clock-item {
    width: 155px;
    position: relative;
    margin: 0 10px;
}
.countdown-timer .clock .text {
    position: absolute;
    top: 0;
    width: 100%;
}
.countdown-timer .clock .type-time {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 0;
}
.countdown-timer .clock .kineticjs-content {
    position: relative;
}
.countdown-timer .clock .kineticjs-content:after {
    content: '';
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    z-index: -1;
}
.countdown-timer .clock .val {
    font-size: 70px;
    color: #fff;
    width: 100%;
    line-height: 155px;
    text-align: center;
    margin-bottom: 15px;
}
.countdown-timer .clock {
    display: flex;
}

.bottom-left,
.bottom-right,
.posi-center,
.top-left,
.top-right {
    position: fixed;
    z-index: 2;
    display: block;
}

.bottom-right {
    bottom: 60px;
    right: 60px;
}
.bottom-left {
    bottom: 60px;
    left: 60px;
}
.top-right {
    top: 60px;
    right: 60px;
}
.top-left {
    top: 60px;
    left: 60px;
}

.style-3 .dez-title {
    font-size: 60px;
    line-height: 70px;
    color: #011463;
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: 800;
}

.dez-social-icon {
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 0;
    text-align: center;
    li {
        display: inline-block;
        a {
            display: inline-block;
            width: 48px;
            height: 48px;
            line-height: 48px;
            border-radius: 48px;
            font-size: 18px;
            background: #fff;
            transition: all 0.5s;
            -moz-transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -ms-transition: all 0.5s;
            -o-transition: all 0.5s;
        }
    }
}

/* ===============================
	Coming Soon 3
================================ */
.style-3 .sy-box {
    background: #031322;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.style-3 .sy-pager {
    display: none;
}
.style-3 .dez-title {
    color: #fff;
}
.style-3 .dez-social-icon li a {
    color: var(--primary);
    margin-right: 5px;
}
.style-3 .sy-slide > img {
    height: 120%;
    object-fit: cover;
    min-height: 120vh;
}

@media only screen and (max-width: 1400px) {
    .bottom-left {
        bottom: 40px;
        left: 40px;
    }
    .bottom-right {
        bottom: 40px;
        right: 40px;
    }
    .top-left {
        top: 40px;
        left: 40px;
    }
    .top-right {
        top: 40px;
        right: 40px;
    }
    .clock-item {
        width: 130px;
    }
    .countdown-timer .clock .val {
        font-size: 50px;
        line-height: 130px;
    }
}

@media only screen and (max-width: 991px) {
    .posi-center,
    .center-md,
    .posi-center,
    .bottom-right,
    .bottom-left,
    .top-right,
    .top-left {
        position: unset;
    }
    .style-3 .top-left,
    .style-3 .bottom-left {
        width: 100%;
        text-align: center;
    }
    .style-3 .bottom-left {
        padding-bottom: 30px;
    }
    .style-3 .countdown-timer {
        margin-bottom: 35px;
    }
    .style-3 .dez-social-icon li a {
        margin-left: 3px;
        margin-right: 3px;
    }
    .style-3 .center-md .bottom-right {
        position: unset;
    }
    .countdown-timer .clock {
        justify-content: center;
    }
    .style-3.raincanvas .dez-coming-bx {
        position: relative;
        z-index: 2;
    }
    .style-3 .dez-title {
        font-size: 40px;
        line-height: 55px;
    }
    .clock-item {
        width: 120px;
    }

    .style-3 .center-md {
        min-height: calc(100vh - 115px);
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 0;
    }
}

@media only screen and (max-width: 576px) {
    .clock-item {
        width: 70px;
    }
    .countdown-timer .clock .val {
        line-height: 70px;
        font-size: 30px;
        margin-bottom: 5px;
        font-weight: 600;
    }
    .style-3 .dez-title {
        font-size: 28px;
        line-height: 36px;
    }
    .countdown-timer .clock .type-time {
        letter-spacing: 1px;
        font-size: 11px;
        line-height: 18px;
    }
}
