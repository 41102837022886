.eventImageContainer {
  top: -2151px;
  left: 4272px;
  overflow: hidden;
  align-items: center;
  margin: auto;
  width: 100%;
}

.eventImage {
  width: 100%;
  height: 100%;
}

.hero-slider {
  margin: 10px 0!important;
  width: 100%;
  /* margin: auto; */
  position: relative;
}

.event-swiper {
  position: relative;
  z-index: 2;
}

.OverLayPagination {
  position: absolute;
  width: 99%;
  top: 45%;
  z-index: 1;
  margin: auto;
}

.OverLayPagination span {
  cursor: pointer;
}

.previous-style2 {
  text-align: left;
}

.previous-style2 svg {
  float: left;
}

.next-style2 {
  text-align: right;
}

.next-style2 {
  float: right;
}
