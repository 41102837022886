// FILTER BUTTONS CSS
.site-filters {
    margin-bottom: 30px;
    ul {
        margin: 0;
        list-style: none;
    }
    li {
        display: inline-block;
        padding: 0;
        margin-bottom: 3px;
        &.btn {
            box-shadow: none;
            -webkit-box-shadow: none;
            outline: none !important;
        }
        input {
            display: none;
        }
        a {
            margin: 0 5px 5px 0;
            display: block;
        }
        &.active {
            [class*='btn'] {
                color: #fff;
                background-color: #efbb20;
            }
        }
    }
    &.center {
        text-align: center;
        ul {
            display: inline-block;
            margin: auto;
        }
        [class*='btn'] {
            display: inline-block;
            margin: 0 5px 10px;
            text-transform: uppercase;
            @include respond('phone') {
                margin: 0 1px 5px;
                padding: 5px 10px;
                font-size: 12px;
            }
        }
    }
}
