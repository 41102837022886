// Header Logo
.logo-header {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #efbb20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 180px;
    height: 80px;
    position: relative;
    z-index: 9;
    a {
        display: table-cell;
        vertical-align: middle;
    }
    img {
        height: 80px;
        max-width: 180px;
        object-fit: contain;
        @include transitionMedium;
        @include respond('tab-port') {
            max-width: 180px;
            height: 80px;
        }
        @include respond('phone') {
            max-width: 150px;
        }
    }

    // Text Logo
    span {
        font-size: 20px;
        letter-spacing: 20px;
    }
}

// Top Bar
.top-bar {
    background-color: #fff;
    color: #212529;
    padding: 12px 0;
    position: relative;
    border-bottom: 1px solid #d7d7d7;

    .dz-topbar-left {
        float: left;
        li {
            padding-right: 20px;
        }
    }
    .dz-topbar-right {
        float: right;
        li {
            padding-left: 10px;
        }
    }
    .dz-social {
        li {
            a {
                color: inherit;
                font-size: inherit;
                &:hover {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
    .dz-topbar-inner {
        margin-left: -15px;
        margin-right: -15px;
    }
    .dz-topbar-center,
    .dz-topbar-left,
    .dz-topbar-right {
        padding-left: 15px;
        padding-right: 15px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;
            li {
                display: inline-block;
                font-size: 15px;
            }
            i {
                margin-right: 5px;
            }
            a {
                color: inherit;
                &:hover {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
        .dz-social-icon {
            li {
                padding-left: 0;
            }
        }
    }
    .dz-topbar-right {
        ul {
            margin-left: 15px;
        }
    }
    @include respond('tab-port') {
        display: none;
    }
}

// Quik Search
.dz-quik-search {
    background: rgba(33, 37, 41, 0.95);
    position: fixed;
    height: 100%;
    width: 100%;
    top: -100%;
    left: 0;
    padding: 0px 30px;
    z-index: 999;
    overflow: hidden;
    display: none;
    @include transitionMedium;

    form {
        width: 100%;
        max-width: 1200px;
        margin: auto;
        position: relative;
        top: 50%;
        @include transitionMedium;
        opacity: 1;
        transform: translateY(-50%);
    }
    .form-control {
        padding: 15px 60px 15px 60px;
        width: 100%;
        height: 90px;
        border: none;
        background: 0 0;
        color: #fff;
        font-size: 20px;
        border-bottom: 2px solid #fff;
        border-radius: 0;

        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.8);
        }

        &:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.8);
        }

        &::placeholder {
            color: rgba(255, 255, 255, 0.8);
        }
    }
    span {
        position: absolute;
        right: 15px;
        top: 50%;
        margin: -15px 0;
        height: 25px;
        font-size: 20px;
        cursor: pointer;
    }
    button {
        background: transparent;
        border: 0;
        line-height: 1;
        font-size: 30px;
        color: #fff;
        position: absolute;
        top: 30px;
        left: 15px;
    }
    &.On {
        top: 0;
    }
}

// Site Header
.site-header {
    position: relative;
    z-index: 9999;
    .main-bar {
        background: $white;
        width: 100%;
        position: relative;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    }
    .container {
        position: relative;
    }
    .extra-nav {
        float: right;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 9;
        height: 80px;
        padding-left: 30px;

        @include respond('phone-land') {
            display: none;
        }
        .extra-cell {
            display: flex;
            align-items: center;
            & > * {
                margin-left: 20px;
                @include respond('tab-port') {
                    margin-left: 15px;
                }
            }
        }
        @include respond('tab-port') {
            height: 80px;
        }
        @include respond('wide-desktop') {
            padding-left: 0;
        }
        #quik-search-btn {
            color: #fff;
            cursor: pointer;
            margin-right: 0px;
            border: 0;
            width: 45px;
            height: 45px;
            background: var(--secondary);
            line-height: 45px;
            text-align: center;
            font-size: 18px;
            border-radius: var(--border-radius-base);
        }
        .btn {
            @include respond('phone') {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        ul {
            li {
                display: inline-block;
                .icon {
                    padding: 0;
                    background: transparent;
                    min-width: 40px;
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 4px;
                }
            }
        }
    }
}

// Navbar Toggler
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 39px 15px;
    padding: 0;
    float: right;
    outline: none !important;
    @include respond('tab-port') {
        margin: 18px 0px 16px 15px;
    }
}

// Navicon
.navicon {
    width: 45px;
    height: 45px;
    background: var(--primary);
    box-shadow: 0 5px 15px -10px var(--primary);
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    border-radius: var(--border-radius-base);

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 1px;
        opacity: 1;
        background: #fff;
        left: 10px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 13px;
            width: 22px;
        }
        &:nth-child(2) {
            top: 22px;
            width: 25px;
        }
        &:nth-child(3) {
            top: 32px;
            width: 20px;
        }
    }

    &.open span:nth-child(1) {
        top: 23px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    &.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
    }
    &.open span:nth-child(3) {
        top: 23px;
        width: 23px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
}
// Header-tb
.header-tb .navicon span {
    background: #fff;
}

// Header Nav
.header-nav {
    padding: 0;

    @include respond('tab-port') {
        overflow-y: scroll;
        position: fixed;
        width: 60px;
        left: -280px;
        height: 100vh !important;
        @include transitionMedium;
        top: 0;
        background-color: $white;
        margin: 0;
        z-index: 99;
    }
    &.show {
        @include respond('tab-port') {
            left: -1px;
            @include transitionSlow;
            margin: 0;
            width: 280px;
        }
    }
    .logo-header {
        display: none;
        @include respond('tab-port') {
            padding: 30px 20px;
            float: none;
            height: auto;
            width: 240px;
        }
    }
    .nav {
        float: right;
        padding: 0;
        font-size: 0;

        & > li {
            margin: 0px;
            font-weight: 400;
            position: relative;
            &.has-mega-menu {
                position: inherit;
            }
            @include respond('tab-port') {
                border-bottom: 1px solid #eee;
                width: 100%;
            }
            &.menu-item-has-children,
            &.sub-menu-down {
                & > a {
                    &:after {
                        content: '\f078';
                        font-size: 9px;
                        margin-left: 5px;
                        margin-top: 0;
                        vertical-align: middle;
                        font-weight: 900;
                        font-family: 'Font Awesome 5 Free';

                        @include respond('tab-port') {
                            content: '\f054';
                            background-color: var(--primary);
                            color: #fff;
                            height: 30px;
                            line-height: 30px;
                            right: 0;
                            text-align: center;
                            width: 30px;
                            z-index: 3;
                            float: right;
                            font-size: 14px;
                            margin: 0;
                            border-radius: var(--border-radius-base);
                        }
                        &:before {
                            @include respond('tab-port') {
                                content: '\f078';
                            }
                        }
                    }
                }
                @include respond('tab-port') {
                    &.open {
                        & > a {
                            &:after {
                                content: '\f078';
                                background: var(--secondary);
                            }
                        }
                    }
                }
            }
            & > a {
                color: var(--secondary);
                font-size: 15px;
                padding: 32px 15px;
                font-family: var(--font-family-title);
                cursor: pointer;
                font-weight: 600;
                display: inline-block;
                position: relative;
                border-width: 0 0 0 1px;
                text-transform: capitalize;
                line-height: 1;
                @include respond('tab-land') {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                @include respond('tab-port') {
                    padding: 8px 0;
                    display: block;
                    line-height: 30px;
                    font-size: 16px;
                }
                span {
                    display: inline-block;
                }
            }
            .mega-menu,
            .sub-menu {
                background-color: #fff;
                display: block;
                left: 0;
                list-style: none;
                opacity: 0;
                padding: 15px 0;
                position: absolute;
                visibility: hidden;
                width: 220px;
                z-index: 10;
                margin-top: 20px;
                text-align: left;
                border-radius: 0 var(--border-radius-base)
                    var(--border-radius-base) var(--border-radius-base);
                @include transitionSlow;
                box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);

                @include respond('tab-port') {
                    display: none;
                    position: static;
                    visibility: visible;
                    width: auto;
                    background: transparent;
                    box-shadow: none;
                    margin: 0;
                    border-top: 1px solid #eee;
                    padding: 10px 0;
                    @include transitionNone;
                    & > li > a i {
                        color: #fff !important;
                        height: 30px;
                        line-height: 30px;
                        right: 0;
                        text-align: center;
                        width: 30px;
                        z-index: 3;
                        font-size: 14px !important;
                        margin: 0 !important;
                        background: #aaa;
                    }
                }
                .menu-item-has-children {
                    & > a:before {
                        content: '\f054';
                        font-weight: 900;
                        font-family: 'Font Awesome 5 Free';
                        float: right;
                        font-size: 10px;
                        margin-top: 2px;
                    }
                    @include respond('tab-port') {
                        &.open > a:before {
                            content: '\f054';
                        }
                    }
                }

                @include respond('tab-port') {
                    display: none;
                    position: static;
                    visibility: visible;
                    width: auto;
                    background: transparent;
                    box-shadow: none;
                    margin: 0;
                    border-top: 1px solid #eee;
                    padding: 0 0;
                    @include transitionNone;
                    opacity: 1;
                    & > li > a i {
                        display: none;
                    }
                    .menu-item-has-children > a:before,
                    & > li > a i {
                        color: #fff !important;
                        font-size: 14px;
                        content: '\f054';
                        height: 30px;
                        line-height: 30px;
                        right: 0;
                        text-align: center;
                        width: 30px;
                        z-index: 3;
                        margin: 0 !important;
                        background: var(--primary);
                        border-radius: var(--border-radius-base);
                    }
                    .menu-item-has-children.open > a:before {
                        content: '\f078';
                        background: #333;
                    }
                }
                li {
                    position: relative;
                    a {
                        color: #212529;
                        display: block;
                        font-size: 15px;
                        padding: 10px 20px;
                        line-height: 1.3;
                        text-transform: capitalize;
                        position: relative;
                        transition: all 0.15s linear;
                        -webkit-transition: all 0.15s linear;
                        -moz-transition: all 0.15s linear;
                        -o-transition: all 0.15s linear;
                        font-weight: 400;

                        @include respond('tab-port') {
                            padding: 4px 0 4px 15px;
                            display: block;
                            line-height: 30px;
                            position: relative;
                            border: 1px solid #eee;
                            border-width: 0 0 1px 0px;
                            &:after {
                                content: '';
                                font-family: 'Font Awesome 5 Free';
                                width: auto;
                                height: auto;
                                background: 0 0 !important;
                                top: 3px;
                                color: #262f5a;
                                font-size: 12px;
                                position: absolute;
                                left: 0;
                                font-weight: 700;
                            }
                        }
                    }
                    &:hover > a {
                        color: var(--primary);
                        @include respond('tab-port') {
                            padding: 4px 0 4px 15px;
                            background: transparent;
                        }
                        &:after {
                            width: 10px;
                        }
                    }
                }
                li > .sub-menu {
                    left: 220px;
                    @include transitionMedium;

                    @include respond('tab-port') {
                        display: none;
                    }
                }
                @include respond('tab-port') {
                    .mega-menu,
                    .sub-menu {
                        border-top: 0;
                        padding-left: 20px;
                        li a:after {
                            width: 5px;
                            content: none;
                            height: 5px;
                            background: #262f5a !important;
                            border-radius: 4px;
                            top: 13px;
                        }
                    }
                }
            }
            .sub-menu {
                li > a > i {
                    color: inherit;
                    display: block;
                    float: right;
                    font-size: 18px;
                    opacity: 1;
                    margin-top: 0px;
                    @include respond('tab-port') {
                        display: none;
                    }
                }
            }
            .mega-menu {
                display: flex;
                right: 0px;
                width: 100%;
                z-index: 9;
                padding: 0;
                & > li {
                    display: block;
                    padding: 15px 0 15px;
                    position: relative;
                    vertical-align: top;
                    width: 25%;
                    @include respond('tab-port') {
                        width: 100%;
                        padding: 0;
                    }
                    & > a {
                        color: $dark;
                        display: block;
                        padding: 0 20px;
                        font-size: 16px;
                        font-weight: 600;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        padding-bottom: 15px;
                        & > i {
                            font-size: 14px;
                            margin-right: 5px;
                            text-align: center;
                            width: 15px;
                        }
                        @include respond('tab-port') {
                            display: none;
                        }
                    }
                    &:after {
                        content: '';
                        background-color: rgba(0, 0, 0, 0.1);
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        display: block;
                        width: 1px;
                        height: 100%;
                        @include respond('tab-port') {
                            content: none;
                        }
                    }
                    &:last-child:after {
                        display: none;
                    }
                    &:hover > a {
                        background-color: transparent;
                    }
                    li i {
                        font-size: 14px;
                        margin-right: 5px;
                        text-align: center;
                        width: 15px;
                    }
                }
                @include respond('tab-port') {
                    display: none;
                    padding: 10px 0;
                }
            }
            &.active {
                & > a {
                    color: var(--primary);
                }
            }
            &:hover {
                & > a {
                    color: var(--primary);
                }
                & > .mega-menu,
                & > .sub-menu {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0;
                    @include transitionSlow;
                    @include respond('tab-port') {
                        @include transitionNone;
                    }
                    li:hover > .sub-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                        top: -1px;
                    }
                }
            }
            &.open {
                .mega-menu,
                .sub-menu {
                    display: block;
                    opacity: 1;
                    @include transitionNone;
                }
                .open {
                    &.menu-item-has-children > a:before {
                    }
                    .mega-menu,
                    .sub-menu {
                        display: block;
                        opacity: 1;
                        @include transitionNone;
                    }
                }
            }
        }
        @include respond('laptop') {
            & > li:nth-last-child(4),
            & > li:nth-last-child(3),
            & > li:nth-last-child(2),
            & > li:last-child {
                .sub-menu .sub-menu {
                    left: -220px;
                }
            }
        }
        @include respond('tab-port') {
            float: none;
            padding: 0 20px;
        }
    }
    .sidebar-footer {
        display: none;
        @include respond('tab-port') {
            display: block;
            padding-top: 30px;
            padding-bottom: 20px;
            margin-top: auto;
            width: 100%;
            text-align: center;
        }
        .dz-login-register {
            text-align: center;
            padding-bottom: 10px;
        }
    }
    .dz-social-icon {
        text-align: center;
        display: none;
        li {
            display: inline-block;
            margin: 0 2px;
        }
        a {
            width: 40px;
            height: 40px;
            padding: 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            line-height: 38px;
            text-align: center;
            border-radius: var(--border-radius-base);
        }
        @include respond('tab-port') {
            display: block;
            padding-top: 40px;
            padding-bottom: 20px;
        }
    }
}

// Header Transparent{
.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .top-bar,
    .main-bar {
        background: transparent;
        box-shadow: none;
    }
    .container-fluid {
        padding-left: 80px;
        padding-right: 80px;
        @include respond('tab-land') {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    // Side Menu Btn
    .side-menu-btn {
        .menu-icon-in {
            span {
                background-color: $white;
            }
        }
    }
    .is-fixed {
        .side-menu-btn {
            .menu-icon-in {
                span {
                    background-color: $dark;
                }
            }
        }
    }
    @include respond('tab-port-min') {
        .nav {
            & > li {
                & > a {
                    color: $white;
                }
            }
        }
    }
    .navbar-toggler {
        span {
            background: $white;
        }
    }
    .is-fixed {
        .nav {
            & > li {
                & > a {
                    color: $dark;
                }
            }
        }
        .navbar-toggler {
            span {
                background: $dark;
            }
        }
    }

    // Top Bar
    .top-bar {
        border-color: $white;
        .dz-topbar-left,
        .dz-topbar-right {
            li,
            i {
                color: $white;
            }
        }
    }
}

// Is Fixed
.is-fixed {
    .main-bar {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
        background: $white;
        transition: all 0.5s;
    }
}

// Mobile Nav
@include respond('tab-port') {
    .mo-left {
        .header-nav {
            overflow-y: scroll;
            position: fixed;
            width: 280px;
            left: -280px;
            height: 100vh !important;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            top: 0;
            background-color: #fff;
            margin: 0;
            z-index: 99;
            &.nav-dark {
                background-color: #202020;
            }
            &.show {
                left: -1px;
                transition: all 0.8s;
                -webkit-transition: all 0.8s;
                -moz-transition: all 0.8s;
                -o-transition: all 0.8s;
                margin: 0;
                width: 280px;
            }
            .logo-header {
                display: block;
                float: none;
                height: auto !important;
                max-width: 100%;
                padding: 30px 20px;
                width: 100% !important;
                img {
                    max-width: unset;
                    width: 180px;
                    height: auto;
                    vertical-align: middle;
                }
            }
            li {
                &.open {
                    a {
                        position: relative;
                    }
                }
            }
        }
        .navbar-toggler {
            &.open {
                z-index: 99;
                &:after {
                    background-color: rgba(0, 0, 0, 0.6);
                    content: '';
                    left: 0;
                    position: fixed;
                    right: -20px;
                    top: -63px;
                    transform: scale(100);
                    -o-transform: scale(100);
                    -moz-transform: scale(100);
                    -webkit-transform: scale(100);
                    width: 100%;
                    z-index: -1;
                    transition: all 0.5s;
                    transform-origin: top right;
                    margin: 0 0px 0px 20px;
                    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
                    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
                }
                span {
                    background: #fff;
                }
            }
        }
    }
}
.text-black {
    .nav {
        & > li {
            & > a {
                color: $dark;
            }
        }
    }
    .navbar-toggler {
        span {
            background: $dark;
        }
    }
}

// Start Header is-fixed Animation
.main-bar {
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}
.is-fixed .main-bar {
    -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}
@-moz-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}
@-ms-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}
@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}
@keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}
// Header is-fixed Animation End

.shop-cart {
    display: inline-block;

    .cart-btn {
        width: 35px;
        height: 40px;
        display: inline-block;
        position: relative;
        padding: 10px 0 2px 0;
        text-align: center;
        @include respond('phone') {
            width: 25px;
            height: 34px;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            border: 2px solid var(--secondary);
            display: block;
        }
        &:before {
            width: 20px;
            height: 8px;
            left: 7px;
            top: 0;
            border-width: 2px 2px 0px 2px !important;
            border-radius: 5px 5px 0 0;
            @include respond('phone') {
                width: 15px;
                height: 8px;
                left: 5px;
            }
        }
        &:after {
            width: 100%;
            height: 30px;
            border-radius: 0 0 6px 6px;
            bottom: 0;
            left: 0;
            @include respond('phone') {
                height: 24px;
            }
        }
        .badge {
            background: var(--primary);
            padding: 0;
            position: unset;
            min-width: 18px;
            height: 18px;
            line-height: 18px;
            font-weight: 400;
            @include respond('phone') {
                padding: 2px 3px;
                position: unset;
                min-width: auto;
                height: auto;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}

//css
.drop-head {
    .dropdown-toggle {
        font-size: 14px;
        color: black;
        font-weight: 500;
        text-align: center;
        font-family: 'Poppins';
        cursor: pointer;
    }
}

.profile-dropdown {
    .dropdown-toggle {
        cursor: pointer;
    }
}
