.list-circle,
.list-angle-double,
.list-square,
.list-square-check,
.list-check-circle,
.list-check,
.list-plus-circle,
.list-arrow-left-circle,
.list-arrow-right-circle {
    li {
        position: relative;
        padding: 5px 5px 5px 30px;
        font-family: var(--font-family-title);
        font-size: 16px;

        &:before {
            position: absolute;
            left: 0;
            top: 8px;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
        }
    }
    &.primary {
        li {
            &:before {
                color: var(--primary);
            }
        }
    }
    &.white {
        li {
            &:before {
                color: #fff;
            }
        }
    }
    &.gray {
        li {
            &:before {
                color: #cbcbcb;
            }
        }
    }
}
ul {
    &.list-circle {
        li {
            padding: 5px 5px 5px 22px;
            &:before {
                content: '\f111';
                font-size: 6px;
                top: 15px;
            }
        }
    }
    &.list-angle-double {
        li {
            &:before {
                content: '\f101';
                font-size: 18px;
            }
        }
    }
    &.list-square {
        li {
            &:before {
                content: '\f45c';
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &.list-check {
        li {
            &:before {
                content: '\f00c';
                font-size: 16px;
            }
        }
    }
    &.list-check-circle {
        li {
            &:before {
                content: '\f058';
                font-size: 18px;
                font-weight: 500;
                top: 5px;
            }
        }
    }
    &.list-plus-circle {
        li {
            &:before {
                content: '\f055';
                font-size: 18px;
                top: 5px;
            }
        }
    }
    &.list-arrow-left-circle,
    &.list-arrow-right-circle {
        li {
            &:before {
                font-size: 10px;
                top: 10px;
                height: 20px;
                width: 20px;
                background-color: #888888;
                border-radius: 50px;
                text-align: center;
                line-height: 21px;
            }
        }
    }
    &.list-arrow-right-circle {
        li {
            &:before {
                content: '\f054';
                padding-left: 1px;
            }
        }
    }
    &.list-arrow-left-circle {
        li {
            &:before {
                content: '\f053';
                padding-right: 1px;
            }
        }
    }
    &.grid-2 {
        display: flex;
        flex-wrap: wrap;
        li {
            width: 50%;
        }
        @include respond('phone') {
            li {
                width: 100%;
            }
        }
    }
}
.list-square-check {
    li {
        padding: 10px 15px 10px 60px;
        min-height: 45px;
        font-weight: 600;
        color: var(--secondary);
        margin-bottom: 15px;

        &:before {
            content: '\f00c';
            top: 50%;
            height: 45px;
            width: 45px;
            line-height: 45px;
            background-color: var(--rgba-primary-1);
            color: #000;
            text-align: center;
            padding: 0 0 0 0;
            @include transitionSlow;
            transform: translateY(-50%);
        }
        &:hover {
            &:before {
                background-color: var(--primary);
                color: #fff;
            }
        }
    }
}

.list-number-circle {
    li {
        padding: 0px 15px 0px 70px;
        position: relative;
        margin-bottom: 30px;
        &:after {
            content: attr(data-text);
            position: absolute;
            left: 0;
            top: 0;
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 50px;
            background: var(--primary);
            color: #fff;
            font-family: var(--font-family-title);
            font-size: 24px;
            font-weight: 600;
        }
        p {
            margin-bottom: 0;
        }
    }
}

.list-square-box {
    li {
        padding: 0px 0 0px 25px;
        position: relative;
        margin-bottom: 30px;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            height: 12px;
            width: 12px;
            background: var(--primary);
        }
        p {
            margin-bottom: 0;
            font-size: 17px;
        }
    }
}
.list-check-box {
    li {
        padding: 20px 10px 20px 70px;
        position: relative;
        margin-bottom: 10px;
        color: #fff;
        background: var(--secondary);
        font-size: 24px;
        font-family: var(--font-family-title);
        border-radius: var(--border-radius-base);
        text-transform: uppercase;
        margin-right: 40px;
        @include transitionSlow;
        font-weight: 500;
        &:after {
            content: '\f058';
            font-size: 25px;
            font-weight: 500;
            position: absolute;
            left: 30px;
            color: #fff;
            font-family: 'Font Awesome 5 Free';
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover {
            background: var(--primary);
            margin-right: 0;
        }
        @include custommq($max: 1200px) {
            font-size: 20px;
        }
        @include respond('phone') {
            font-size: 18px;
            padding: 20px 10px 20px 55px;
            &:after {
                left: 20px;
            }
        }
    }
}
